.bg-overlay {

  z-index: -1 !important;
}
.nav-bg ul li {
  margin: 30px 0px 0px 0;
  letter-spacing: 10px;
  text-align: center;
}
#style-2::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}
#style-2::-webkit-scrollbar-thumb {
  border-radius: 0;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #00252f;
}
#style-2::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  border-radius: 0;
  background-color: #f5f5f5;
}
.call-back-btn{
  padding: 15px;
  background: #03a84e;
  width: 30px;
  position: fixed;
  bottom: 20px;
  left:20px;
  
  z-index: 2;
  height: 30px;
  border-radius: 50%;
}
.modal-open {
overflow: hidden;
}

.modal {
position: fixed;
top: 0;
right: 0;
bottom: 0;
left: 0;
z-index: 1050;
display: none;
overflow: hidden;
outline: 0;
-webkit-overflow-scrolling: touch;
}


.modal.in .modal-dialog {
-webkit-transform: translate(0, 0);
-ms-transform: translate(0, 0);
 -o-transform: translate(0, 0);
    transform: translate(0, 0);
}

.modal-open .modal {
overflow-x: hidden;
overflow-y: auto;
}

.modal-dialog {
position: relative;
width: auto;
margin: 10px;
}

.modal-content {
position: relative;
background-color: #fff;
-webkit-background-clip: padding-box;
    background-clip: padding-box;
border: 1px solid rgba(0, 0, 0, 0.2);
border-radius: 0.3rem;
outline: 0;
}

.modal-backdrop {
position: fixed;
top: 0;
right: 0;
bottom: 0;
left: 0;
z-index: 1040;
background-color: #000;
}

.modal-backdrop.fade {
opacity: 0;
}

.modal-backdrop.in {
opacity: 0.5;
}

.modal-header {
padding: 15px;
border-bottom: 1px solid #e5e5e5;
}

.modal-header::after {
content: "";
display: table;
clear: both;
}

.modal-header .close {
line-height: 1;
float: right;
font-size: 40px;
}

.modal-title {
margin: 0;
line-height: 1.5;
}

.modal-body {
position: relative;
padding: 15px;
}

.modal-footer {
padding: 15px;
text-align: right;
border-top: 1px solid #e5e5e5;
}

.modal-footer::after {
content: "";
display: table;
clear: both;
}

.modal-scrollbar-measure {
position: absolute;
top: -9999px;
width: 50px;
height: 50px;
overflow: scroll;
}

@media (min-width: 544px) {
.modal-dialog {
max-width: 600px;
margin: 30px auto;
}
.modal-sm {
max-width: 300px;
}
}

@media (min-width: 992px) {
.modal-lg {
max-width: 900px;
}
}

video{
  object-fit: cover;
  
  height: 100%;
  width: 100%;
}
.ulset{
  color: #fff;
  justify-content: left;
  font-size:30px ;
  line-height: 1px;
  padding: 100px 110px 100px 50px;

}
.title-style{
  font-size: 50px !important;
font-family:   'Work Sans', sans-serif;
}
.small-title{
  font-size: 18px !important;
font-family:  'Raleway', sans-serif !important;
font-weight: lighter;
padding-right: 20px;

}
.swiper-button-next{
  margin-bottom: 55px;
}
.swiper-button-prev{
  margin-bottom: 55px;
  
}
.overlay-dark-bg-2{
  width: 90%;
}
.portfolio-content-bg {
  background-size: 106% 102%!important;
}
.price-list{
  font-family: 'Readex Pro', sans-serif !important;
}
.title-style {
  text-transform: uppercase;
  font-weight:bold;
  /* text-align: center; */
  letter-spacing: 2px;
  font-family: 'American Typewriter', serif;
  line-height: 1;
  /* font-size: 30px !important; */
  /* margin-left: 179px;
  text-align: center; */
}
/* p{
  text-transform: lowercase !important;
}
.small-title{
  text-transform: capitalize !important;
} */
.tawk-bubble-text-container {
  background-color: #00A8E8  !important;
}
